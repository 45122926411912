@import "../../atoms/menu/itemMenu.scss";

@mixin listMenu {
    .aside_menu_nav_list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        width: 100%;
        height: 100%;

        padding: 0;

        @include itemMenu;

        &:last-child {
            width: auto;
        }
    }

    @media (min-width: $laptop) {
        .aside_menu_nav_list {
            flex-direction: column;
            justify-content: flex-start;
            gap: 1rem;

            border-top: 2px solid var(--neutral-light-color);

            padding-top: 1rem;
            margin: 0;

            &:last-child {
                display: flex;
                position: absolute;
                bottom: 1rem;
                height: auto;
                width: 4.3rem;
            }
        }
    }
}

@mixin listMenu-active {
    .aside_menu_nav_list {
        @include itemMenu-active;

        &:last-child {
            width: calc(100% - 2rem);
        }
    }
}
