.nuestrau_nav_list {
    display: flex;
    grid-template-rows: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: row;
    width: 78%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.nuestrau_nav_list_item {
    position: relative; /* Necesario para que el pseudo-elemento se posicione correctamente */
    align-self: flex-end;

    border-radius: 8px;
    background: var(--primary-color);
    padding: 88px;
    margin: 10px;

    width: 27rem;
    height: 19rem;
    
    background-size: cover;
    background-position: center;
    overflow: hidden; /* Para que el pseudo-elemento no salga de los bordes */
    
    &:hover {
        background-color: var(--neutral-color);
        box-shadow: 0 0 10px var(--neutral-color);
        cursor: pointer;
    }

    a {
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: var(--light-color);
        font-size: 3rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
    }

    /* Pseudo-elemento para la capa de transparencia */
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
        z-index: 1; 
        border-radius: 8px; 
    }

    a {
        position: relative;
        z-index: 2; 
    }
}

@media (min-width: 1024px) {
    .nuestrau_nav_list {
        margin-bottom: 30px;
    }
}
