.nuestrau_main {
    width: 100%;
    overflow: hidden;
}

.nuestrau_fondo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100%;
}

.nuestrau_section_first {
    position: relative;
    width: 100%;
}

.nuestrau_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100vw;
}

.nuestrau_text {
    line-height: 24px;
    width: 70vw;
    text-align: center;
    margin: 20px;
}

.nuestrau_link_universidad {
    text-decoration: none;
    border-radius: 10px;
    background: var(--background-color);
    color: var(--light-color);
    font-size: 1.6rem;
    padding: 10px 20px;
    margin-right: 10px;

    &:hover {
        background-color: var(--primary-light-color);
        box-shadow: 0 0 10px var(--primary-dark-color);
    }
}

@media (min-width: 800px) {
    .nuestrau_main {
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: var(--primary-dark-color);
    }

    .nuestrau_fondo {
        background-color: var(--light-color);
        width: 88vw;
    }

    .nuestrau_section_first {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75vw;
        height: 400px;
        border-radius: 15px;
        overflow: hidden;

        margin-top: 30px;
    }

    .nuestrau_nav {
        margin-top: 20px;
        flex-direction: row;
    }

    .nuestrau_link_universidad {
        padding: 10px 40px;
    }
}

@media (min-width: 1024px) {
    .nuestrau_text {
        position: absolute;
        top: 242px;
        right: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 25rem;
        height: 14rem;
        background-color: var(--light-color);
        border-radius: 15px;
        box-shadow: 0px 0px 15px var(--neutral-dark-color);
        padding: 25px;
        z-index: 1;
        font-size: 1.4rem;
    }

    .nuestrau_section_first {
        align-self: flex-start;
        width: 70vw;

        margin-left: 40px;
    }
}

@media (min-width: 1440px) {
    .nuestrau_fondo {
        width: 80vw;
    }

    .nuestrau_text {
        top: 274px;
        right: 200px;
        width: 25rem;
        height: 13rem;
        font-size: 1.4rem;
    }

    .nuestrau_section_first {
        align-self: flex-start;
        width: -webkit-fill-available;
        /* height: 60vh; */
        margin: 0px 60px;
        margin-top: 60px;

        margin-left: 60px;
        margin-top: 60px;
    }
}
