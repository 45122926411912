.container_images_presentation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100vw;
}

.nuestrau_images_slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 300px;
    min-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.3s ease all;
}

.nuestrau_images_gradiente {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 40%;
    background: linear-gradient(180deg, var(--black-color) 0%, rgba(255, 255, 255, 0) 100%);
}

.nuestrau_section_first {
    display: flex;
    flex-direction: column;
}

.nuestrau_images_title {
    position: absolute;
    top: 10px;
    align-self: center;

    text-align: center;
    font-size: 1.8rem;
    font-weight: normal;
    text-align: center;
    color: var(--light-color);

    padding-top: 20px;
    z-index: 1;
}

.nuestrau_images_presentation_ui {
    position: relative;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: var(--transparent-color);
    width: 100%;
}

.nuestrau_carril_imagenes {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 10px;
}

@media (min-width: 600px) {
    .nuestrau_images_slide {
        height: 400px;
    }
}

@media (min-width: 800px) {
    .nuestrau_Images_Slide {
        height: 450px;
        min-width: 87.5vw;
    }
}

@media (min-width: 1024px) {
    .nuestrau_Images_Slide {
        min-width: 85vw;
    }
}

@media (min-width: 1440px) {
    .nuestrau_Images_Slide {
        height: 60vh;
    }
}
