.education_offering_filter_and_cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
}

.search_courses {
    display: flex;
    justify-content: center;
    align-items: center;
}

.courses_ref_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.courses_paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.search_courses {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 3px solid #0066d0;
}

.search_courses input {
    border: none;
    border-radius: 15px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.6rem;
}

.search_courses ion-icon {
    background-color: #0066d0;
    color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0 10px 10px 0;
}

.paginator_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}

.limits_paginator_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.limits_paginator_buttons ion-icon {
    font-size: 3rem;
    color: #0066d0;
}

.paginator_buttons button {
    background-color: transparent;
    border: none;
}

.page_selected_button {
    color: #0066d0;
    font-size: 1.5rem;
    font-weight: 600;
}

@media (max-width: 768px) {
    .paginator_buttons {
        width: 100vw;
        gap: 0;
        font-size: 0.9rem;
    }

    .paginator_buttons button:not(.next_page_button):not(.previous_page_button):not(.page_selected_button) {
        display: none;
    }

    .limits_paginator_buttons ion-icon {
        font-size: 2rem;
    }

    .page_selected_button {
        font-size: 1.2rem;
    }
}