.contact_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
    .contact_section {
        /* order: 3;
        border-radius: 25px;
        border: 2px solid #89b3b4;
        width: 80%; */

        order: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 10px;
        margin-bottom: 30px;
        width: 80%;
        justify-content: center;

        .adjust {
            width: 100%;
            border-radius: 23px 23px 0px 0px;
        }

        .contact_container{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4rem;
        }
    }
}

.contact_section_menu_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .contact_section_menu_nav_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        list-style: none;
        width: 100%;
        max-width: 95vw;

        margin: 0;
        padding: 0;
        gap: 1rem;
        
        button {
            position: relative; 
            width: 100%;
            max-width: 32rem;
            min-height: 38rem;
            height: 100%;
            background-color: var(--light-color);
            background-image: url('../../../assets/img/oferta/Principal/Formacion-continua.png');
            background-size: cover;
            background-position: center;
            border-radius: 2rem !important;
        
            margin: 3rem 0.5rem !important;
            transition: 0.2s;
        
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 1rem;
            color: white;
            border: 3px solid var(--primary-strong-color);
            text-align: center;
            text-decoration: none;
            font-size: 5rem;
            font-weight: bolder;
        
            &:hover {
                transform: scale(1.02);
                background-color: var(--neutral-color);
                border: 3px solid #47749f;
                color: white;
            }
        
            /* Capa de transparencia oscura */
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgb(0 0 0 / 35%);
                z-index: 1; 
                border-radius: 1.8rem; 
                transition: background-color 0.2s; 
            }
        
            /* Asegura que el contenido esté encima de la capa */
            span {
                position: relative;
                z-index: 1; 
            }
        }

        .btn_active_state {
            position: relative;
            bottom: 3px;
            border: 1px solid var(--primary-light-color);
            box-shadow: 3px 3px 10px var(--neutral-color);
            transition: 0.2s;
        }
    }
}

.contact_title {
    order: 1;
    font-size: 1.8rem;

    margin: 10px;
    margin-top: 30px;
}

.contact_separador {
    order: 2;
    width: 80vw;
    height: 3px;
    background-color: var(--neutral-light-color);

    margin: 10px;
}

.contact_text {
    order: 3;
    width: 50vw;
    text-align: center;
    font-size: var(--text);
}

.contact_section_cards {
    order: 4;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    margin: 10px;
    margin-bottom: 30px;
    width: 80%;
    justify-content: center;
}

.contact_info {
    width: 100%;    
    text-align: left;
    margin-top: 30px;
    padding: 10px 9%;
    border-radius: 10px;
/*     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */

    h2 {
      font-size: 2.5rem;
      color: #1e73be;
      margin-bottom: 15px;
    }
  
    p {
      font-size: 1.6rem;
      margin: 10px 0;
      line-height: 1.5;
    }
  
    a {
      color: #1e73be;
      font-weight: bold;
      text-decoration: none;
      transition: color 0.3s ease;
  
      &:hover {
        color: #0c4a89;
        text-decoration: underline;
      }
    }
  
    ul {
      list-style: disc inside;
      margin: 20px 0;
      padding-left: 20px;
      
      li {
        font-size: 1.6rem;
        margin: 5px 0;
      }
    }
  }

  .contact_info_columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    padding: 10px 6%;
    
    // Asegurar que se vea en dos columnas en pantallas más grandes
    @media (min-width: 800px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.contact_column {
    width: 100%;
    padding: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;

    // Borde en el lado izquierdo de la segunda columna para separar visualmente
    &:nth-child(2) {
        border-left: 3px solid var(--primary-strong-color);
        padding-left: 10%;
    }

    // Para pantallas más grandes
    @media (min-width: 800px) {
        width: 48%;
    }

    h2 {
        font-size: 2.5rem;
        color: var(--primary-strong-color);
        margin-bottom: 45px;
        align-self: center;
    }

    p {
        font-size: 1.6rem;
        line-height: 2.5;
        margin: 5px 0;

        &:nth-of-type(1) {
            color: var(--primary-strong-color);
            line-height: 0.6;
        }
    }

    a {
        color: #1e73be;
        text-decoration: none;
        font-weight: bold;
        &:hover {
            color: #0c4a89;
            text-decoration: underline;
        }
    }
}

@media (min-width: 800px) {
    .contact_section_cards {
        flex-direction: row;
    }
}

@media (min-width: 1024px) {
    .contact_text {
        margin-bottom: 20px;
    }
}
