.not_found_container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not_found_title {
    color: var(--primary-color);
    font-size: 3rem;
    margin-bottom: 1rem;
}

.not_found_img {
    max-width: 80vw;
    height: 40vh;
    padding: 5vh;
}

.not_found_text {
    width: 21rem;
    font-size: 1.4rem;
    text-align: center;
}

.not_found_text span {
    font-size: 4rem;
}

.not_found_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--light-color);
    height: 50px;
    width: 180px;
    background-color: var(--primary-color);
    border-radius: 5px;
    margin-top: 1rem;

    &:hover {
        box-shadow: 0px 0px 18px var(--black-color);
    }
}
