@import "../../molecules/menu/btnMenu.scss";
@import "../../molecules/menu/ListMenu.scss";

@mixin menu {
    .aside_menu {
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100vw;
        height: 7.5rem;

        margin: 0;
        padding: 0;
        z-index: 10;

        .aside_menu_nav {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
            border: 1px solid var(--neutral-light-color);
            border-bottom: none;
            border-radius: var(--border) var(--border) 0 0;
            background-color: var(--light-color-2);
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.562);

            margin: 0;
            padding: 0;

            @include btnMenu;
            @include listMenu;
        }
    }

    @media (min-width: $laptop) {
        .aside_menu {
            left: 1rem;
            top: 50%;
            transform: translate(0, -50%);
            justify-content: flex-start;

            width: 6.5rem;
            height: 36rem;
            border: 1px solid var(--neutral-light-color);
            border-radius: calc(var(--border) + 1rem);
            background-color: var(--light-color-2);

            z-index: 10;
            transition: 0.2s;

            &.true {
                width: 25rem;
                transition: 0.2s;

                .aside_menu_nav {
                    @include btnMenu-active;
                    @include listMenu-active;
                }
            }

            .aside_menu_nav {
                position: relative;
                justify-content: flex-start;
                flex-direction: column;

                width: 100%;
                height: 100%;
                border: none;
                border-radius: calc(var(--border) + 1rem);
                box-shadow: none;

                padding: 1rem;
            }
        }
    }
}
