@mixin itemMenu {
    .aside_menu_item {
        position: relative;  // Necesario para el posicionamiento del tooltip
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 3rem;
        width: 6.5rem;
        text-decoration: none;
        color: var(--primary-dark-color);

        padding: 0.8rem;
        margin: 0 0.5rem;
        transition: 0.2s;

        &:hover ion-icon {
            color: var(--primary-light-color);
            transition: 0.2s;
        }

        &.active {
            background-color: var(--light-color-2);
            transition: 0.2s;
        }

        .aside_menu_item_links {
            position: relative; // Necesario para el tooltip
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            text-decoration: none;
            font-size: var(--subtitle);
            color: var(--neutral-dark-color);
            padding: 2rem;
            transition: 0.2s;

            &.active {
                background-color: transparent;
                color: var(--primary-light-color);
                transition: 0.2s;

                p {
                    color: var(--primary-light-color);
                    transition: 0.2s;
                }
            }

            p {
                white-space: pre;
                color: var(--neutral-color);
                font-size: var(--small);
                margin-top: 0.5rem;
                transition: 0.2s;

                &:hover {
                    color: var(--primary-light-color);
                    transition: 0.2s;
                }
            }

            // Tooltip Styles
            &::after {
                content: attr(data-title); /* Usar data-title para el contenido del tooltip */
                position: absolute;
                top: 50%; /* Centramos verticalmente respecto al elemento */
                left: 100%; /* Posicionamos a la derecha del elemento */
                transform: translateX(25px) translateY(-50%);
                background-color: #f7f8f9;
                color: #4d4d4d;
                padding: 0.5rem;
                border-radius: 4px;
                white-space: nowrap;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.3s;
                font-size: var(--small);
                z-index: 1000;
            }

            &:hover::after {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    @media (min-width: $laptop) {
        .aside_menu_item {
            justify-content: center;

            height: 4.3rem;
            width: 100%;
            border-radius: var(--border);
            border: 1px solid var(--light-color-2);

            padding: 0.5rem;
            margin: 0;
            transition: 0.2s;

            &:hover {
                background-color: var(--light-dark-color);
                border: 1px solid var(--neutral-light-color);

                cursor: pointer;
                transition: 0.2s;

                a {
                    color: var(--primary-light-color);
                }
            }

            &.active {
                background-color: var(--primary-light-color);

                ion-icon {
                    color: var(--light-color);
                }

                p {
                    color: var(--light-color);
                }
            }

            .aside_menu_item_links {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                width: 100%;
                font-size: var(--text);
                color: var(--neutral-dark-color);

                padding: 0;

                ion-icon {
                    font-size: var(--huge);
                }

                p {
                    display: none;
                }

                // Tooltip Styles
                &::after {
                    content: attr(data-title); 
                    position: absolute;
                    top: 50%; 
                    left: 100%;
                    transform: translateX(25px) translateY(-50%);
                    background-color: #f7f8f9;
                    color: #4d4d4d;
                    padding: 0.5rem;
                    border-radius: 4px;
                    white-space: nowrap;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.3s;
                    font-size: var(--text);
                    z-index: 1000;
                }

                &:hover::after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

@mixin itemMenu-active {
    .aside_menu_item {
        &.active {
            p {
                color: var(--light-color);
            }
        }

        .aside_menu_item_links {
            justify-content: flex-start;
            gap: 2rem;
            padding: 0 2rem;

            p {
                display: block;
                font-size: var(--text);
                margin-top: 0;
            }
        }
    }
}