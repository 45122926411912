/* breakpoint */
$laptop: 700px;
$desktop: 1024px;
$desktop-xl: 1440px;

/* Vars */

:root {
    /* Paleta de colores */
    --background-color: linear-gradient(90deg, #00aee9 0%, #0c4c9b 100%);
    --transparent-color: transparent;

    /* Monocrómaticos */

    --light-light-color: #ffffff;
    --light-color: #f0f0f0;
    --light-color-2: #f7f8f9;
    --light-dark-color: #eef0f3;

    --black-light-color: #3f3f3f;
    --black-color: #222222;
    --black-dark-color: #1e1e1e;

    --neutral-light-color: #d9d9d9;
    --neutral-color: #868686;
    --neutral-dark-color: #4d4d4d;

    --contrast-color: #b2b2be;
    --contrast-dark-color: #575760;

    /* Colores */

    --gov-color: #3366cc;

    --primary-very-light-color: #0298d3;
    --primary-light-color: #47749f;
    --primary-color: #004991;
    --primary-dark-color: #044c82;
    --primary-very-dark-color: #0b253a;
    --primary-accent-color: #00609b;
    --primary-strong-color: #0075c9;
    --primary-dark-transparent: #0b253ab0;

    --fef-1: #9d1d96;
    --fef-2: #a50064;
    --fef-3: #5c0f8b;

    --fba-1: #e52538;
    --fba-2: #b2043e;
    --fba-3: #841940;

    --fct-1: #ffd100;
    --fct-2: #eddb00;
    --fct-3: #c2d500;

    --fed-1: #8cc63f;
    --fed-2: #22b573;
    --fed-3: #39b54a;

    --fhu-1: #ff9e18;
    --fhu-2: #ff8300;
    --fhu-3: #ed670c;

    /* Componentes web */

    --buttons: #47749f;
    --buttons-hover: #3b5f83;

    --links-1: #126ea8;
    --links-hover: #1d88ca;

    /* Tamaños */

    --max: 48px;
    --giant: 36px;
    --title: 30px;
    --huge: 24px;
    --subtitle: 20px;
    --big: 18px;
    --text: 16px;
    --small: 14px;
    --tiny: 12px;
    --border: 15px;
    --min: 10px;
}
