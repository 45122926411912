@mixin logoUpn {
    .header_logo_upn {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 60px;
        text-decoration: none;
        background: var(--primary-dark-transparent);
        border: 1px solid var(--primary-very-dark-color);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.6px);
        -webkit-backdrop-filter: blur(7.6px);
        border-radius: 0 0 var(--border) var(--border);

        padding: 0 15px;
        margin: 0;
        transition: 0.2s;

        .small {
            height: 38px;
        }

        .large {
            opacity: 0;
            width: 0;
            height: 30px;
        }

        .text {
            display: none;
            font-size: var(--subtitle);
            color: var(--light-color);
            border-left: 2px solid var(--light-color);

            padding: 1rem;
            padding-left: 2rem;
            margin-left: 2rem;
            text-decoration: auto;
        }
    }

    @media (min-width: $laptop) {
        .header_logo_upn {
            border-radius: var(--border);

            .text {
                display: block;
            }
        }
    }

    @media (min-width: $desktop) {
        .header_logo_upn {
            .large {
                opacity: 1;
                width: auto;
                margin-left: 1rem;
            }
        }
    }
}

@mixin logoUpn-scrolled {
    .header_logo_upn {
        background: transparent;
        border: none;
        box-shadow: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        border: none;

        transition: 0.2s;

        .large {
            opacity: 1;
            width: auto;
            margin-left: 1rem;
            transition: 0.2s;
        }
    }
}
