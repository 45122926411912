@import "../components/atoms/buttons.scss";

@mixin home {
    .home_main {
        padding-top: 0;

        .home_main_article {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            min-height: 28rem;
            width: 100%;
            text-align: center;

            padding: 10rem 0;
            padding-bottom: 5rem;
            z-index: 0;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--black-color);
                background: -webkit-linear-gradient(to right, rgba(15, 15, 15, 0.9), rgba(20, 20, 20, 0.1));
                background: linear-gradient(to right, rgba(15, 15, 15, 0.9), rgba(20, 20, 20, 0.1));
                -webkit-backdrop-filter: blur(4px);
                backdrop-filter: blur(0px);
                z-index: -1;
            }

            .home_main_title {
                font-size: var(--giant);
                font-weight: normal;
                color: var(--light-light-color);

                padding: 0 30px;
            }

            .home_main_caption {
                font-size: var(--big);
                font-weight: normal;
                color: var(--neutral-light-color);

                padding: 10px 30px;
            }

            .home_main_text {
                font-size: var(--text);
                font-weight: normal;
                line-height: 150%;
                color: var(--light-color);

                padding: 10px 40px;
            }

            .home_main_btn {
                @include buttons;

                margin: 4rem 0;
            }
        }

        .home_main_article_white {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            padding-bottom: 5vh;
        }
    }

    @media (min-width: $laptop) {
        .home_main {
            .home_main_article {
                padding: 10rem 0;
                padding-top: 15rem;

                .home_main_title {
                    font-size: var(--giant);
                }

                .home_main_caption {
                    font-size: var(--big);
                }

                .home_main_text {
                    width: 85vw;
                    font-size: var(--text);
                }
            }

            .home_main_article_white {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                height: 13vh;
                padding-bottom: 5vh;
            }
        }
    }

    @media (min-width: $desktop) {
        .home_main {
            .home_main_article {
                padding-top: 17rem;
                padding-bottom: 12rem;

                .home_main_title {
                    font-size: var(--max);
                }

                .home_main_caption {
                    font-size: var(--huge);
                }

                .home_main_text {
                    max-width: 90rem;
                    font-size: var(--text);
                }
            }
        }
    }
}
