@mixin btnMenu {
    .menu-icon {
        display: none;
    }

    @media (min-width: $laptop) {
        .menu-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            font-size: var(--huge);

            padding: 1rem;

            .grid-icon {
                display: flex;
                align-items: center;
                justify-content: center;

                width: var(--huge);
                height: var(--huge);
                font-size: var(--huge);
                background-color: var(--light-color-2);
                border: none;
                padding: 0;

                &:hover {
                    cursor: pointer;
                    color: var(--primary-color);
                }

                span {
                    display: none;
                }
            }

            .handler-icon {
                position: absolute;
                right: -2rem;

                display: flex;
                align-items: center;
                justify-content: center;

                width: var(--subtitle);
                height: var(--subtitle);
                font-size: var(--subtitle);
                background-color: var(--light-color-2);
                border-radius: 50%;
                border: 1px solid var(--neutral-light-color);
                padding: 0;

                &:hover {
                    cursor: pointer;
                    color: var(--primary-color);
                }
            }
        }
    }
}

@mixin btnMenu-active {
    .menu-icon {
        justify-content: flex-start;

        .grid-icon {
            gap: 2rem;
            width: auto;

            span {
                text-align: start;
                display: block;
                font-size: var(--text);
                color: var(--neutral-dark-color);
            }
        }
    }
}
