.button_anchor {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: #47749f;
    color: white;
    border-radius: 9px;
    border: 3px solid #005dc0;
    text-align: center;
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 500;
}

.button_anchor:hover {
    background-color: #005dc0;
    border: 3px solid #47749f;
    color: white;
}