@mixin gov {
    .GOV_container {
        background-color: var(--gov-color);
        width: 100%;

        .GOV {
            height: 4.8rem;

            .GOV-logo {
                height: 4.8rem;
            }

            a {
                display: flex;
                align-items: center;
                height: 4.8rem;
                padding: 0 1rem;
            }
        }
    }
}
