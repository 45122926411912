@mixin loadingPages {
    .loading_page {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--background-color);
        width: 100%;
        height: 100%;
        z-index: 1000;
        transition: 1s;
    }

    .spinner-grow {
        width: 7vw !important;
        height: 7vw !important;
    }

    .text-light {
        position: relative;
        z-index: 2;
        color: var(--light-color);
        font-size: 4rem;
    }

    #pagina_cargada {
        transition: 1s;
        animation: fadeIm 0.4s ease;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
    }

    .off {
        width: 0;
        height: 0;
        display: none;
    }

    @keyframes fadeIm {
        0% {
            opacity: 100%;
        }

        100% {
            opacity: 0%;
        }
    }
}
