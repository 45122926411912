@import "../../../styles/components/atoms/buttons.scss";

@mixin btnLogin {
    .btn_login {
        @include buttons;

        background: var(--background-color);
        background-color: var(--primary-very-light-color);
        padding: 1rem;
        transition: 0.2s;

        &:hover {
            cursor: pointer;
            background: var(--neutral-dark-color);
            background-color: var(--neutral-dark-color);
            transition: 0.2s;
        }

        ion-icon {
            font-size: var(--subtitle);
        }

        span {
            display: none;
        }
    }

    @media (min-width: $laptop) {
        .btn_login {
            padding: 1rem 2rem;

            span {
                display: block;
            }
        }
    }
}
