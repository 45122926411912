@mixin images {
    .institutional_identity {
        display: none;
        align-items: center;
        justify-content: center;

        background: var(--primary-dark-transparent);
        border: 1px solid var(--primary-very-dark-color);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.6px);
        -webkit-backdrop-filter: blur(7.6px);
        border-radius: var(--border);

        margin-left: 2rem;
        transition: 0.2s;

        .identity-img {
            height: 5.8rem;

            padding: 0.7rem;
        }
    }

    @media (min-width: $laptop) {
        .institutional_identity {
            display: flex;
        }
    }
}

@mixin images-scrolled {
    .institutional_identity {
        background: transparent;
        border: none;
        box-shadow: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        border: none;
        transition: 0.2s;
    }
}
