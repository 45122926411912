.course {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  max-width: 30rem;
  border-radius: 2rem;
  margin-bottom: 5rem;
  transition: 0.2s;
  z-index: 0;
}

.course:hover {
  transition: 0.2s;
  border: 1px solid brightness(0.8);
  box-shadow: 0 4px 8px rgba(87, 87, 87, 0.171);
}

.course h3 {
  position: absolute;
  top: 0;
  left: 0;

  color: white;
  font-weight: 700;
  border-radius: 0 2rem 2rem 0;

  margin: 1.4rem;
  margin-left: 0;
  padding: 1rem 2rem;
  font-size: 1.7rem;

  z-index: 2;
}

.course figure {
  position: relative;
  width: 100%;
  height: 17rem;
  overflow: hidden;
  border-radius: 2rem 2rem 0 0;
}

.course figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course figure img:last-child {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  object-fit: fill;
  width: 20rem;
  height: auto;
}

.course article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.course article div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.course article div .first_course_info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
  width: auto;
  padding: 0;
  margin-bottom: 2rem;
}

ion-icon {
  font-size: 2rem;
}

.course article div .first_course_info ion-icon {
  background: transparent;
}

.course article div .first_course_info p {
  margin: 0;
}

.course article p {
  line-height: 1.4em;
  font-weight: normal;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.course article a {
  position: absolute;
  bottom: -2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70%;
  border: none;
  color: white;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 5rem;
  text-decoration: none;

  padding: 1rem 3rem;
  margin: 0 auto;
  margin-top: 2rem;
  transition: 0.3s;
  text-align: center;
}

.course article a:hover {
  filter: brightness(0.9);
  transition: 0.3s;
}
