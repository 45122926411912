@mixin buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    font-size: var(--text);
    color: var(--light-color);
    background: var(--buttons);
    border: 0.1px solid var(--contrast-dark-color);
    border-radius: 3rem;
    padding: 1rem 2rem;
    transition: 0.3s;

    &:hover {
        cursor: pointer;
        background: var(--buttons-hover);
        color: var(--light-color);
        transition: 0.3s;
    }
}
