.mi_espacio_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mi_espacio_title {
    order: 1;
    font-size: 1.8rem;

    margin: 10px;
    margin-top: 30px;
}

.mi_espacio_separador {
    order: 2;
    width: 80vw;
    height: 3px;
    background-color: var(--neutral-light-color);

    margin: 10px;
}

.mi_espacio_text {
    order: 3;
    width: 50vw;
    text-align: center;
    font-size: var(--text);
}

.mi_espacio_section_cards {
    order: 4;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    margin: 10px;
    margin-bottom: 30px;
    width: 80%;
    justify-content: center;
}

@media (min-width: 800px) {
    .mi_espacio_section_cards {
        flex-direction: row;
    }
}

@media (min-width: 1024px) {
    .mi_espacio_text {
        margin-bottom: 20px;
    }
}
