.repository_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.repository_title {
    order: 1;
    font-size: 1.8rem;
    margin: 10px;
    margin-top: 30px;
    font-weight: 600;
}

.repository_separador {
    order: 2;
    width: 80vw;
    height: 3px;
    background-color: var(--neutral-light-color);
    margin: 10px;
}
