.nuestrau_carril_imagenes_item {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: var(--light-color);

    margin: 0.3rem;

    &:hover {
        width: 1.3rem;
        height: 1.3rem;
        cursor: pointer;
    }
}
