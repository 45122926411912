.home_main_circle_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_main_circle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  font-size: 1rem;
  border-radius: 50%;
  color: var(--ColorAzulOscuro);
  width: 14rem;
  height: 14rem;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;

  transition: 0.2s;
}

.home_main_circle_figure {
  display: block;
  width: 90%;
  height: 90%;
  background-position: center;
  background-repeat: no-repeat;
}

.home_main_circle_container {
  border-bottom: 2px solid var(--ColorAzulOscuro);
}

.home_main_circle_container:last-child {
  border: none;
}

.home_main_circle_figure:hover {
  width: 100%;
  height: 100%;
  transition: 0.2s;
}

.home_main_circle_text {
  position: relative;
  bottom: 45px;
}

@media (min-width: 600px) {
  .home_main_circle {
    width: 10rem;
    height: 10rem;
  }

  .home_main_circle {
    top: -3rem;
  }
}

@media (min-width: 800px) {
  .home_main_circle {
    width: 20rem;
    height: 20rem;
  }
}
