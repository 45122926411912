.explora_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.explora_title {
    order: 1;
    font-size: 1.8rem;
    margin: 10px;
    margin-top: 30px;
    font-weight: 600;
}

.explora_separador {
    order: 2;
    width: 80vw;
    height: 3px;
    background-color: var(--neutral-light-color);
    margin: 10px;
}

.explora_section {
    order: 3;
    border-radius: 25px;
    border: 2px solid #89b3b4;
    width: 80%;
}

.explora_section_menu_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .explora_section_menu_nav_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        list-style: none;
        width: 100%;
        max-width: 95vw;

        margin: 0;
        padding: 0;
        gap: 1rem;
        
        button {
            width: 100%;
            max-width: 32rem;
            min-height: 38rem;
            height: 100%;
            background-color: var(--light-color);
            background-image: url('../../../assets/img/oferta/Principal/Formacion-continua.png');
            background-size: cover;
            background-position: center;
            border-radius: 1.4rem !important;
            border: 1px solid var(--neutral-color);

            margin: 3rem 0.5rem !important;
            transition: 0.2s;

            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 1rem;
            color: white;
            border: 3px solid var(--neutral-color);
            text-align: center;
            text-decoration: none;
            font-size: 5rem;
            font-weight: bolder;

            &:hover {
                transform: scale(1.02);
                background-color: #005dc0;
                border: 3px solid #47749f;
                color: white;
            }
        }

        .btn_active_state {
            position: relative;
            bottom: 3px;
            border: 1px solid var(--primary-light-color);
            box-shadow: 3px 3px 10px var(--neutral-color);
            transition: 0.2s;
        }
    }
}

/* Estilos para los botones de facultades */
.faculties_filter button {
    margin: 5px;
    border: 2px solid rgb(96, 96, 96);
    background-color: #f0f0f0;
    cursor: pointer;
    background-size: auto;
    background-position: center;
    padding: 15px;
    border-radius: 10px;
    color: white;
    font-weight: bolder;
}

.faculties_filter button.active {
  background-color: #007bff;
  color: white;
  box-shadow: 3px 3px 10px var(--neutral-color);
}

.show-all-button {
    margin: 5px;
    border: none;
    background-color: var(--primary-color);
    cursor: pointer;
    background-size: auto;
    background-position: center;
    padding: 15px;
    border-radius: 10px;
    color: white;
    font-weight: bolder;
    &:hover {
        box-shadow: 3px 3px 10px var(--neutral-color);
    }
}

.explora_section_info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .explora_section_info_figure {
        order: 2;
    }

    .explora_section_info_content {
        order: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        padding: 40px 0;

        .explora_section_info_mask {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            background-color: rgba(27, 27, 27, 0.836);
            border-radius: 15px;

            padding: 30px 10px;

            .explora_section_info_title {
                color: var(--light-color);
                font-size: 1.4rem;
                font-weight: normal;

                margin: 10px;
            }

            .explora_section_info_btn {
                text-decoration: none;
                border-radius: 10px;
                background: var(--background-color);
                color: var(--light-color);

                padding: 10px;
                margin: 10px;
            }

            .explora_section_info_text {
                color: var(--light-color);
                text-align: center;
                font-weight: 100;
                line-height: 24px;
                width: 70vw;
                margin: 10px;
            }

            .explora_section_separator {
                width: 75vw;
                height: 2px;
                background-color: var(--neutral-light-color);

                margin-top: 10px;
                margin-bottom: 20px;
            }

            .explora_section_info_caption {
                color: var(--light-color);
                font-size: 1.2rem;
                font-weight: normal;
            }

            .explora_section_info_links_list {
                display: flex;
                align-items: center;
                justify-content: center;

                height: 50px;
                list-style: none;

                padding: 0;
                margin: 0.5rem 0;

                .explora_section_info_links_item {
                    align-self: flex-end;
                    text-decoration: none;
                    color: var(--light-color);
                    background: var(--neutral-dark-color);
                    border-radius: 8px;
                    font-weight: normal;

                    padding: 10px;
                    margin: 0 10px;

                    .explora_section_info_iink {
                        text-decoration: none;
                        color: var(--light-color);
                    }
                }

                .explora_section_info_links_item:hover {
                    background-color: var(--primary-light-color);
                    box-shadow: 0 0 10px var(--neutral-color);
                    cursor: pointer;
                }
            }
        }
    }
}