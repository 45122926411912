@import "../../molecules/header/gov.scss";
@import "../../molecules/header/logoUpn.scss";
@import "../../molecules/header/images.scss";
@import "../../molecules/header/btnLogin.scss";

@mixin header {
    @include gov;

    .header {
        position: fixed;
        top: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;

        padding: 0 1rem;
        margin: auto;
        z-index: 10;
        transition: none;

        &.scrolled {
            background: var(--primary-dark-transparent);
            border-bottom: 1px solid var(--dark);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(7.6px);
            -webkit-backdrop-filter: blur(7.6px);
            transition: 0.2s;

            padding-top: 0;

            section {
                @include logoUpn-scrolled;
            }

            @include images-scrolled;
        }

        @include images;

        section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 130rem;

            @include logoUpn;
            @include btnLogin;
        }
    }

    @media (min-width: $laptop) {
        .header {
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 6rem;
            top: 1rem;

            .header_btn {
                display: none;
            }

            .header_text {
                margin-right: 15px;
            }
        }
    }

    @media (min-width: $desktop-xl) {
        .header {
            padding-left: 2rem;
        }
    }
}
