@import "_vars";

/* Componentes */
@import "./components/containers/loadingPages.scss";
@import "../components/organisms/menu/menu.scss";
@import "../components/organisms/header/header.scss";
@import "../components/organisms/footer/footer.scss";

/* Pages */
@import "./pages/home.scss";

/* Fuentes */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
    height: 100%;
    min-height: auto;
    font-size: 62.5%;

    body {
        position: relative;

        overflow-x: hidden;
        height: 100%;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
            "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &::-webkit-scrollbar {
            width: 7px;
            background-color: var(--neutral-dark-color);
            color: var(--light-color);
        }

        &::-webkit-scrollbar-thumb {
            background: var(--primary-light-color);
            border-radius: 5px;
        }

        #root {
            /* display: flex; */
            flex-direction: column;
            align-items: center;
            width: 100%;
            overflow-x: hidden;

            @include loadingPages;
            @include header;
            @include menu;
            @include footer;

            main {
                align-self: flex-end;
                width: 100%;
                padding: 5rem 0;
            }

            @include home;
        }
    }
}
