@mixin footer {
    footer {
        justify-self: flex-end;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        bottom: 0;

        text-align: center;
        width: 100%;
        background: var(--primary-color);
        transition: none;

        & * {
            transition: none;
        }

        .footer-institutional {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            font-size: 162.5%;

            .gov-barra-inferior-afuera {
                margin: 0;
            }
        }

        .footer-gov {
            width: 100%;

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
        }

        .footer-rights {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            background-color: var(--black-color);
            padding: 1rem 2rem;

            p {
                color: var(--neutral-light-color);
                font-size: var(--small);
            }
        }
    }
}
